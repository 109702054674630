import { WppTooltip } from '@wppopen/components-library-react'
import { useState, useCallback, useRef, useEffect, ReactNode } from 'react'

import styles from './CellWithTooltip.module.scss'

interface Props {
  tooltipValue?: string
  forceTooltip?: boolean
  htmlElementToRender?: ReactNode
}

export const CellWithTooltip = ({ tooltipValue, forceTooltip = false, htmlElementToRender }: Props) => {
  const cellRef = useRef<HTMLDivElement>(null)

  const [addTooltip, setAddTooltip] = useState(false)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const handleTruncate = useCallback(() => {
    if (!forceTooltip && (cellRef.current === null || !tooltipValue)) {
      setAddTooltip(false)
      return
    }
    if (cellRef.current) {
      setDimensions({ width: cellRef.current.offsetWidth, height: cellRef.current.offsetHeight })
      setAddTooltip(forceTooltip || cellRef.current.offsetWidth < cellRef.current.scrollWidth)
    }
  }, [tooltipValue, forceTooltip])

  useEffect(() => {
    if (cellRef?.current) {
      setTimeout(() => {
        handleTruncate()
      }, 150)
      window.addEventListener('resize', handleTruncate)
    }
    return () => {
      window.removeEventListener('resize', handleTruncate)
    }
  }, [handleTruncate])
  return (
    <div>
      <div ref={cellRef} className={styles.truncate}>
        {htmlElementToRender ?? tooltipValue}
      </div>
      {addTooltip && (
        <div className={styles.tooltip}>
          <WppTooltip text={tooltipValue}>
            <div style={{ width: `${dimensions.width}px`, height: `${dimensions.height}px` }} />
          </WppTooltip>
        </div>
      )}
    </div>
  )
}

export default CellWithTooltip
