import { WppActionButton, WppAvatar, WppIconMore, WppListItem, WppMenuContext } from '@wppopen/components-library-react'
import { ColDef } from 'ag-grid-community'

import CellWithTooltip from 'components/Grid/CellWithTooltip'
import { ApiSortTypes } from 'hooks/useApiSortFilters'

import { TableDataItem } from './AgencyAgGrid'
import { sortType } from '../AgencyFilterGroup/types'

export const columnDefenitions = (
  handleEditAgencyClick: (id: string) => void,
  handleDeleteAgencyClick: (id: string, name: string) => void,
  sortingState: ApiSortTypes<sortType>,
): ColDef[] => [
  {
    field: 'name',
    sortable: true,
    ...(sortingState['name'] && { sort: sortingState['name'] === 'ASC' ? 'asc' : 'desc' }),
    headerName: 'Name',
    flex: 1.5,
    rowDrag: false,
    cellClass: 'truncate',
    cellRenderer: ({ data }: { data: TableDataItem }) => <CellWithTooltip tooltipValue={data.name} />,
    comparator: () => 0,
  },
  {
    field: 'numberOfCategories',
    sortable: true,
    ...(sortingState['numberOfCategories'] && { sort: sortingState['numberOfCategories'] === 'ASC' ? 'asc' : 'desc' }),
    headerName: 'Knowledge Base',
    comparator: () => 0,
    cellRenderer: ({ data }: { data: TableDataItem }) => `${data.numberOfCategories} categories`,
    flex: 1,
  },
  {
    field: 'createdAt',
    sortable: true,
    ...(sortingState['createdAt'] && { sort: sortingState['createdAt'] === 'ASC' ? 'asc' : 'desc' }),
    headerName: 'Date Created',
    flex: 1,
    comparator: () => 0,
    cellRenderer: ({ data }: { data: TableDataItem }) =>
      new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(new Date(data.createdAt)),
  },
  {
    field: 'createdBy',
    headerName: 'Added By',
    sortable: true,
    ...(sortingState['createdBy'] && { sort: sortingState['createdBy'] === 'ASC' ? 'asc' : 'desc' }),
    comparator: () => 0,
    cellRenderer: ({ data }: { data: TableDataItem }) => (
      <CellWithTooltip
        forceTooltip={true}
        tooltipValue={`${data?.createdBy?.name}
        ${data?.createdBy?.email}`}
        htmlElementToRender={
          <div className="flex flex-row justify-start items-center gap-4 truncate">
            {data.createdBy && (
              <WppAvatar
                size="m"
                className="max-w-8 max-h-8 flex-shrink-0 flex-grow-0 flex-auto"
                name={data.createdBy.name || ''}
                src={data.user?.img || ''}
              />
            )}
            <div className="truncate" style={{ width: 'calc(100% - 32px)' }}>
              {data?.createdBy.name || ''}
            </div>
          </div>
        }
      />
    ),

    flex: 1,
  },
  {
    headerName: '',
    field: 'Actions',
    width: 100,
    cellRenderer: ({ data }: { data: TableDataItem }) => (
      <div className="flex flex-col justify-center h-full">
        <WppMenuContext
          data-context-menu
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <WppActionButton
            slot="trigger-element"
            data-context-menu
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <WppIconMore
              direction="horizontal"
              data-context-menu
              onClick={e => {
                e.stopPropagation()
              }}
            />
          </WppActionButton>
          <div>
            <WppListItem onWppChangeListItem={() => handleEditAgencyClick(data.id)}>
              <p slot="label">Edit</p>
            </WppListItem>
            <WppListItem onWppChangeListItem={() => handleDeleteAgencyClick(data.id, data.name)}>
              <p slot="label">Delete</p>
            </WppListItem>
          </div>
        </WppMenuContext>
      </div>
    ),
  },
]
