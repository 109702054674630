import { InputChangeEventDetail } from '@wppopen/components-library'
import { WppInputCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppActionButton,
  WppIconArrow,
  WppIconDownload,
  WppIconSearch,
  WppInput,
  WppSkeleton,
  WppSpinner,
  WppTypography,
} from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'
import { useEffect, useMemo, useState, useCallback } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { PaginationParams } from 'api/common/types'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { useRfi } from 'api/queries/rfis/useRfi'
import { useUseCases } from 'api/queries/use-cases/useUseCases'
import { ApiPaginator } from 'components/ApiPaginator'
import EmptyState from 'components/EmptyState'
import { useApiSearch } from 'hooks/useApiSearch'
import { useFileViewOrDownload } from 'hooks/useFileViewOrDownload'
import { ProjectDetails } from 'types/rfis/rfi'
import { UseCase, FetchUseCasesFiltersParams } from 'types/use-cases/useCase'

import FileCmp from './FileCmp'
import { ANALYTICS_EVENTS, trackAnalytics } from '../../utils/analytics'

function RelevantNotesPage({ rfi }: { rfi: ProjectDetails }) {
  const [isFetchingFile, viewOrDownloadFile] = useFileViewOrDownload()
  const [selectedFile, setSelectedFile] = useState<UseCase | null>(null)
  const [getAllUsecases, setGetAllUsecases] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const useCaseParams: PaginationParams<FetchUseCasesFiltersParams> | undefined = Object.fromEntries(searchParams)

  const agencyIds = rfi.agencies.map(agency => agency.id)

  const {
    data: { data: useCases, paginator: useCasesPaginator } = {
      data: [],
      paginator: { page: 1, totalItems: 0, totalPages: 0, itemsPerPage: 0 },
    },
    error,
    isLoading,
  } = useUseCases({
    params: { pitchTypeIds: rfi.pitchTypeIds.join(','), ...useCaseParams, 'filter[agencyIds]': agencyIds.join(',') },
    enabled: searchParams.get('page') !== null,
  })

  const [searchUrlParamName, setSearchTerm] = useApiSearch('title', isLoading)

  useEffect(() => {
    if (!searchParams.get('page')) {
      setSearchParams(prev => {
        prev.set('page', '1')
        return prev
      })
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    trackAnalytics(
      {
        type: AnalyticsActionType.page,
        payload: ANALYTICS_EVENTS.RELEVANT_CASES_PAGE_VIEW,
      },
      {
        projectId: rfi.id,
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [searchValue, setSearchValue] = useState<string>(searchParams.get(searchUrlParamName) ?? '')
  const handleSearchChange = useCallback(
    (event: WppInputCustomEvent<InputChangeEventDetail>) => {
      setSearchValue(event.detail.value ?? '')
      setSearchTerm(event.detail.value ?? '')
    },
    [setSearchTerm],
  )

  const {
    data: { data: useCasesAll } = {
      data: [],
    },
    isLoading: isLoadingAllUseCases,
  } = useUseCases({
    params: {
      pitchTypeIds: rfi.pitchTypeIds.join(','),
      page: '1',
      itemsPerPage: useCasesPaginator.totalItems.toString(),
      [searchUrlParamName]: searchValue.length > 0 ? searchValue : null,
    },
    enabled: !isLoading && !error && getAllUsecases,
  })

  const { fileObjects } = useMemo(() => {
    const useCasesArray = getAllUsecases ? useCasesAll : useCases
    const useCasesWithFiles = useCasesArray.filter(useCase => useCase.fileKey && useCase.fileName)
    return {
      fileObjects: useCasesWithFiles.map(useCase => ({ key: useCase.fileKey, name: useCase.fileName! })),
    }
  }, [useCases, useCasesAll, getAllUsecases])

  const getAllFiles = useCallback(() => {
    const { totalItems, itemsPerPage } = useCasesPaginator
    if (itemsPerPage >= totalItems) {
      viewOrDownloadFile({ fileObjects: fileObjects, folderName: 'use-cases' })
    } else {
      setGetAllUsecases(true)
    }
  }, [useCasesPaginator, viewOrDownloadFile, fileObjects])

  useEffect(() => {
    if (getAllUsecases && !isLoadingAllUseCases) {
      if (fileObjects.length > 0) {
        viewOrDownloadFile({ fileObjects: fileObjects, folderName: 'use-cases' })
      }
      setGetAllUsecases(false)
    }
  }, [getAllUsecases, viewOrDownloadFile, fileObjects, isLoadingAllUseCases])

  useEffect(() => {
    trackAnalytics(
      {
        type: AnalyticsActionType.page,
        payload: ANALYTICS_EVENTS.RELEVANT_CASES_PAGE_VIEW,
      },
      {
        projectId: rfi.id,
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isEmpty = useCases.length === 0 && !isLoading && searchValue.length > 0

  const renderView = () => {
    if (isLoading) {
      return (
        <div className="flex gap-3 flex-wrap">
          <WppSkeleton width="30%" height={255} />
          <WppSkeleton width="30%" height={255} />
          <WppSkeleton width="30%" height={255} />
        </div>
      )
    }
    if (!isLoading && (error || (!error && !useCases.length))) {
      return (
        <div className="flex flex-row items-center justify-center">
          <EmptyState title="Nothing to Show" description="There are no items to be shown here" />
        </div>
      )
    }
    return (
      <>
        <div className="flex flex-row items-center flex-wrap justify-start gap-4">
          {useCases.length > 0 &&
            useCases?.map(useCase => (
              <FileCmp key={useCase.id} useCase={useCase} onClick={useCase => setSelectedFile(useCase)} />
            ))}
        </div>

        <ApiPaginator paginator={useCasesPaginator} />

        {isEmpty && <EmptyState title="No Results" description="Try searching by different keywords" />}
      </>
    )
  }

  if (selectedFile) {
    return (
      <div>
        <div className="flex flex-row items-center gap-4">
          <WppActionButton onClick={() => setSelectedFile(null)} variant="secondary">
            <WppIconArrow direction="left" slot="icon-start" />
          </WppActionButton>
          <WppTypography type="xl-heading">Relevant Cases and Pitches / {selectedFile.title}</WppTypography>
        </div>
        <div className="bg-[#F8F9FB] rounded-lg p-4 mt-4">
          <WppTypography color="#121619" type="s-strong">
            {selectedFile.title}
          </WppTypography>
          <WppTypography className="mt-3" color="#4D5358" type="s-body">
            <WppTypography className="max-w-full break-words" type="s-body">
              <span dangerouslySetInnerHTML={{ __html: selectedFile.summary.replace(/\n/g, '<br />') }} />
            </WppTypography>
          </WppTypography>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row items-center justify-between">
        <WppTypography type="xl-heading">Relevant Cases and Pitches</WppTypography>
        <WppActionButton
          disabled={isFetchingFile || getAllUsecases || !fileObjects.length}
          variant="secondary"
          onClick={getAllFiles}
        >
          {isFetchingFile ? <WppSpinner size="s" /> : <WppIconDownload slot="icon-start" />}
        </WppActionButton>
      </div>

      <div className="flex flex-col gap-2">
        <WppTypography className="text-grey" type="s-light">
          Check out proposed use cases and pitches that can help you.
        </WppTypography>
        <WppInput
          name="use-case-search"
          className="w-[300px]"
          placeholder="Search"
          value={searchValue}
          disabled={isLoading}
          onWppChange={handleSearchChange}
        >
          <WppIconSearch slot="icon-start" aria-label="Search icon" />
        </WppInput>
      </div>

      {renderView()}
    </div>
  )
}

export default function RelevantNotesPageWrapper() {
  const params = useParams()
  const {
    data: rfi,
    isLoading: isLoadingRfi,
    error,
  } = useRfi({
    params: { rfiId: params.projectId || '' },
  })
  const { isLoading: isLoadingPitchTypes } = usePitchTypes()

  if (isLoadingRfi || isLoadingPitchTypes) {
    return (
      <div className="flex items-center justify-center">
        <WppSpinner size="m" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex flex-row items-center justify-center">
        <EmptyState title="Nothing to Show" description="There are no items to be shown here" />
      </div>
    )
  }

  return <RelevantNotesPage rfi={rfi} />
}
